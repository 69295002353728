@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* Add any global styles here */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 14px; /* Adjust the root font size */
}

a {
  color: lightskyblue;
}

ul,
ol {
  margin-left: 1rem;
  list-style: initial; /* Ensure default list styles are applied */
}

li {
  margin-bottom: 0.5rem; /* Add some space between list items */
  margin-top: 0.5rem;
}

.markdown-content p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; /* Add space after paragraphs */
}

@media (max-width: 600px) {
  ul,
  ol {
    margin-left: 20px;
    padding-left: 10px;
  }
}

/* Add styles for tables in markdown content */
.markdown-content table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are shared between cells */
  margin-bottom: 1rem; /* Add some space below the table */
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #ddd; /* Add border to table cells */
  padding: 8px; /* Add padding for better readability */
  text-align: left; /* Align text to the left */
}

.markdown-content th {
  font-weight: bold; /* Make header text bold */
}
